.navbar{
    padding: 1rem 2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.24);
    & a {
        text-decoration: none;
        &:hover{
            color: #98029d;
        }
    }
    & button:focus{
        outline: none;
    }
    @media (max-width: 590px) {
    padding: 1rem 2rem;
    }
    @media (max-width: 375px) {
    padding: 1rem 1rem;
    }
}

.logo{
    height: 36px;
}

.search-icon{
    display: none;
    height: 30px;
    @media (max-width: 990px) {
        display: block;
        cursor: pointer;
    }
    @media (max-width: 375px) {
        height: 20px;
    }
}

.cancel-icon{
    cursor: pointer;
    height: 30px;
    svg {
        fill: #F56C00;
    }
}

.searchbarfull{
    @media (max-width: 990px) {
        display: none;
    }
}

.searchbarfull2{
    margin-right: 5px;
}
.hamburger{
    @media (max-width: 990px) {
        display: none;
    }
}

#hiddenNavs{
    display: none;
    & a{
        display: none;
    }
    @media (max-width: 990px) {
        display: block;
        & a{
            display: block;
        }
    }
}

@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        /* padding-right: .5rem; */
        padding-left: .5rem;
    }
}

#basic-navbar-nav{
    position: relative!important;
}

.navbar-toggler{
    border: 0px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../icons/menu.png);
    width: 30px;
    height: 30px;
    @media (max-width: 375px) {
        width: 20px;
        height: 20px;
    }
}

.navbar-light .navbar-toggler {
    border-color: #fff;
}

#form-control-navbar{
    background-color: #EEEEEE;
    border-radius: 16px;
    background-image: url('../../icons/search(1).png');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position-x: calc(100%/2 - 5rem);
    text-indent: 10px;
    background-position-y: calc(100%/2 + 1px);
    &:focus{
        background-image:none;
        border-radius: 16px 16px 0 0;
    }
    &::placeholder {  
        /* Firefox, Chrome, Opera */ 
        text-align: center; 
        color: #333333;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        // line-height: 44px;
        letter-spacing: 0.444px;
    }
}

#minimized-form-control-navbar{
    background-color: #EEEEEE;
    border-radius: 16px;
    width: 92%;
    &::placeholder {  
        /* Firefox, Chrome, Opera */ 
        text-align: center; 
        color: #333333;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 44px;
        letter-spacing: 0.444px;
    }
}

.nav-login{
    color: #F56C00;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.23;
    & :hover{
        color: #98029d;
    }
}

.popover-body{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.444444px;
    color: #B392B5;
    width: 10vw;
    & a {
        text-decoration: none;
        color: #B392B5;
        display: block;
        &:hover{
            color: #B392B5;
        }
    }
}

.advertisement-banner{
    background: url(../../icons/books.png);
    width: 100%;
    height: 50px;
}

//seach cuggestion
    
.autoContainer {
    border-radius: 0 0 16px 16px;
    position: absolute;
    top: 3em;
    color: #333333;
    border-width: 1px;
    border-style: solid;
    border-color: #98029d;
    background-color: #EEEEEE;
    left: 0;
    width: 30%;
    padding: 10px 22px;
    z-index: 100;
    @media (max-width: 986px) {
        width: 85%; 
        border-radius: 16px;
        margin-top: 10px;
        margin-left: 3%;
        margin-left: 4vw;
    }
  }
    
.option{
    // margin-top: 0.5em;
    &:hover{
        color: #555555;
        cursor: pointer;
    }
}