.help-form{
    display: flex;
    justify-content: center;
    margin-top: -15%;
    @media (max-width: 960px) {
        margin-top: -20%;
    }
    @media (max-width: 630px) {
        margin-top: -25%;
    }
    @media (max-width: 500px) {
        margin-top: -33%;
    }
    @media (max-width: 375px) {
        margin-top: -40%;
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border: 1px solid #98029D;
    outline: 0;
    box-shadow: none;
}

#helpForm{
    width: 35vw;
    background-color: #ffffff;
    padding: 40px 30px;
    margin: 40px;
    border-radius: 25px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 1068px) {
        width: 50vw;
    }
    @media (max-width: 768px) {
        width: 60vw;
    }
    @media (max-width: 425px) {
        width: 90vw;
        margin: 40px 0;
    }
}

.helpform-header{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 72px;
    color: #333333;
    text-align: center;
}

.form-label{
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
}

.form-group {
    margin-bottom: 2rem;
}

#helpButton{
    background: #770082;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 20px;
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.text-muted{
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right!important;
    color: #333333;
    margin-top: 20px;
    & a {
        
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        color: #F56C00;
    }
}

//MODAL STYLING
.modal-header{
    border-bottom: none;
    & .close {
    color: #fff;
    font-size: xx-large;
    }
}

.modal-body{
    & h4{
        
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 37px;
        text-align: center;
        color: #FFFFFF;
    }
    & p{
        text-align: center;
        margin: 10%;
    }
    & a{
        
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #FFBC88;
    }
}

.modal-content{
    background-color: #865488;
    border-radius: 20px
}

.lpurple-check{
    text-align: center;
    margin-bottom: 8%;
}

.modal-backdrop.show {
    opacity: 0.1;
}