.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
    margin-bottom: 5px;
    flex: 1; 
	padding: 0 8px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
	&:focus-within {
		border: 1px solid #98029D;
	}
	input {
		flex: 1;
		border: none;
		height: 46px;
		font-size: 14px;
		padding: 4px 0 0 0;
		&:focus {
			outline: transparent;
		}
	}
}

#tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 6px;
	margin: 0 8px 8px 0;
	background:#98029D;
	.tag-title {
		margin-top: 3px;
		word-break: break-word;
	}
	.tag-close-icon {
		display: block;
		min-width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 14px;
		margin-left: 8px;
		color:#98029D;
		border-radius: 50%;
		background: #fff;
		cursor: pointer;
	}
}

.text-area{
	width: 90%;
	border: none;
	&:focus{
		outline: none;
	}
}

#tag-text-area{
    &::placeholder {  
		padding-top: 10px;
    }
}

.AddTagIcon{
    height: 18px;
	margin: auto;
	cursor: pointer;
}