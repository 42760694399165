.horizontal-nav{
    background: #ffffff;
    display: flex;
    margin: 20px 0;
}

.scroll-left{
    background-color: #FAFAFA;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 5px;
    cursor: pointer;
}

.letter-scroll{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    height: fit-content;
    margin: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;
}

.right-icon{
    height: 10px;
}

.left-icon{
    height: 10px;
    transform: scaleX(-1);
}

.letter-scroll::-webkit-scrollbar {
    height: 0;
}

.scroll-right{
    background-color: #FAFAFA;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 5px;
    cursor: pointer;
}

.letter{
    & a{
        font-style: normal;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        color: #98029D;
        text-decoration: none;
        &:hover{
            font-style: normal;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            cursor: pointer;
            color: #98029D;
            text-decoration: none;
        }
    }
    background: #FAFAFA;
    /* border: 3px solid #FAFAFA; */
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 10px;
    padding: 0px 10px;
}