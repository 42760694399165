// .banner{
//     background: linear-gradient(180deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.5) 100%), url(../../icons/banner1.jpg);
//     background-position-y: center;
// }

.header {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
}

.container {
    width: 60vw;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 768px) {
      width: 70vw;
    }
}

.banner-img{
    padding: 60px 10px;
    text-align: center;
    background: #593A5A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    @media (max-width: 768px) {
      border-radius: 0px;
      width: 100vw;
      padding: 0px 10px 10px 10px;
    }
}

.banner-icon{
    height: 40px;
    margin: 25px;
}

.banner-title{
    
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #DADADA;
}

.add-word{
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background: #98029D;
    border-radius: 7px;
    text-align: center;
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        text-decoration: none;
        color: #FDBDFF;
        &:hover{
            color: #98029D;
        }
    }
    &:hover{
        background: #FDBDFF;
        & a {color: #98029D;}
    }
    @media (max-width: 768px) {
      width: 80vw; 
      margin: auto;
      margin-top: 20px;
    }
}

.wow-link{
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none;
    color: #FDBDFF;
    &:hover{
        color: #98029D;
        text-decoration: none;
    }
}

.w-o-w{
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    @media (max-width: 768px) {
      width: 80vw;
      margin: auto;
      margin-top: 20px;
    }
}

.wow-title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #643866;
    text-align: center;
}

.wow-word{
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 48px;
        text-decoration-line: underline;
        color: #F56C00;
    }
}

.definition-title{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
}

.definition-def{
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
}

.advert-space{
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    @media (max-width: 768px) {
        display: none;
    }
}

.banner-footer{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    & a{
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        line-height: 21px;
        color: #555555;
    }
    @media (max-width: 768px) {
      display: none;
    }
}