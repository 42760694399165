.help-banner{
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.44), rgba(51, 51, 51, 0.44)), url(../../icons/helpimage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    padding: 8rem 2rem;
}

.help-header {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
    margin-top: -10%;
    @media (max-width: 960px) {
        margin-top: -15%;
    }
    @media (max-width: 630px) {
        margin-top: -20%;
    }
    @media (max-width: 500px) {
        margin-top: -28%;
    }
    @media (max-width: 375px) {
        margin-top: -35%;
    }
}

.container {
    width: 60vw;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 768px) {
      width: 70vw;
    }
}
