.forgot-form{
    display: flex;
    justify-content: center;
}

#forgotForm{
    width: 35vw;
    background-color: #ffffff;
    padding: 40px 30px;
    margin: 40px;
    border-radius: 25px;
    opacity: 0.8;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 1024px) {
        height: 100%;
    }
    @media (max-width: 768px) {
        width: 60vw;
    }
    @media (max-width: 510px) {
        width: 85vw;
        margin: 40px 0;
    }
}


#forgotForm-alert{
    width: 35vw;
    background-color: #ffffff;
    padding: 40px 30px;
    margin: 40px;
    height: 60vh;
    border-radius: 25px;
    opacity: 0.8;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
        width: 60vw;
    }
    @media (max-width: 425px) {
        width: 90vw;
        margin: 40px 0;
    }
}

.form-header{
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 20px;
    color: rgba(85, 85, 85, 0.78);
    @media (max-width: 425px) {
        font-size: 36px;
    }
}

.form-header-info{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 40px;
    @media (max-width: 425px) {
        font-size: 15px;
    }
}

.form-label{
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
}

.form-group {
    margin-bottom: 2rem;
}

#forgotButton{
    background: #770082;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 20px;
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.text-muted{
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right!important;
    color: #333333;
    margin-top: 20px;
    & a {
        
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        color: #F56C00;
    }
}

.forgot-alert{
    justify-content: center;
    height: 80vh;
}