   
.suggestions {
    top: 3em;
    color: #333333;
    border-width: 1px;
    border-style: solid;
    border-color: #98029d;
    background-color: #ffffff;
    left: 0;
    padding: 10px 22px;
    z-index: 100;
    @media (max-width: 768px) {
        width: 85%; 
        border-radius: 16px;
    }
  }
    
.option{
    // margin-top: 0.5em;
    &:hover{
        color: #555555;
        background-color: rgba(0,0,0,0.3);
        cursor: pointer;
    }
}