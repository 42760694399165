#defcard{
    border-radius: 0 0 20px 20px;
}

.def-wrapper{
    display: flex;
}

.def-wrapper2{
    margin-left: 10px;
    width: 100%;
}

.def-wrapper3{
    display: flex;
    justify-content: space-between;
    @media (max-width: 375px) {
    flex-direction: column;
    }
}

.def-wrapper4{
    display: flex;
    justify-content: space-between;
    @media (max-width: 375px) {
        flex-direction: column;
    }
}

.def-number{
    background: #FFD2AF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    padding: 5px 7px;
    border-radius: 50%;
    color: #666666;
    height: fit-content;
}

.word-def{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #333333;
    white-space: pre-wrap;
    @media (max-width: 425px) {
        line-height: 24px;
    }
}

.word-def-example-title{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    color: rgba(152, 2, 157, 0.75);
    margin-left: 15px;
    @media (max-width: 425px) {
        line-height: 32px;
    }
}

.word-def-example{
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    color: rgba(38, 38, 38, 0.88);
    margin-left: 15px;
    line-height: 36px;
    @media (max-width: 425px) {
        line-height: 24px;
    }
}

.word-def-user{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 48px;
    color: #333333;
    & a{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #F56C00;
    }
}

.verification-icon{
    height: 16px;
    margin-left: 2px;
}

.word-def-more-info{
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        color: #F56C00;
        text-decoration: none;
        padding: 5px 15px;
        vertical-align: text-top;
        background: #FFE5D1;
        border-radius: 36px;
        @media (max-width: 920px) {
            font-size: 14px;
        }
        @media (max-width: 425px) {
            font-size: 12px;
        }
        @media (max-width: 375px) {
            line-height: 48px;
        }
    }
}

.ratings{
    background: #FFFFFF;
    // border: 2px solid #666666;
    box-sizing: border-box;
    border: 2px solid #FFE5D1;
    border-radius: 25px;
    // border-radius: 36px;
    & b{
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        // @media (max-width: 500px) {
        //     font-size: 16px;
        // }
    }
    line-height: 12px;
    padding: 3px 15px 3px 0px;
    color: #555555;
    cursor: pointer;
    & button{    
        background: none;
        border: none;
        cursor: pointer;
        // padding: 1px 6px 1px 0px;
        &:focus{
            outline: none;
        }
        @media (max-width: 375px) {
            padding: 1px 6px 1px 0px;
        }
        &.left-button{
            margin: 0px;
            margin-left: 10px;
            padding: 0;
        }
        &.right-button{
            margin-left: 6px;
            padding: 0;
        }
    }
    @media (max-width: 375px) {
        width: fit-content;
        padding: 0px 15px 3px 0px;
    }
}

.ratings-divider{
    border-right: 2px solid  #FFE5D1;
    padding-right: 6px;
}

.up-vote{
    height: 22px;
    width: 15px;
    transform: scaleY(-1);
    // @media (max-width: 500px) {
    //     width: 12px;
    // }
    &:hover{
        path{
            fill: #07bc0c;
        }
    }
}


.down-vote{
    height: 22px;
    width: 15px;
    margin-bottom: 5px;
    // @media (max-width: 500px) {
    //     width: 12px;
    // }
    &:hover{
        path{
            fill: #e74c3c;
        }
    }
}

.similar-def{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    color: #262626;
    & a{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 48px;
        text-decoration-line: underline;
        color: #F56C00;
        margin-right: 5px;
        margin-left: 5px;
    }
}

.more-def{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.444444px;
    color: #333333;
}

.down-icon{
    height: 14px;
    margin-bottom: 3px;
}

.share-section{
    margin-top: 13px;
}

.share-icons{
    height: 26px;
    margin-right: 1em;
    cursor: pointer;
    // @media (max-width: 468px) {
    //     height: 26px;
    // }
}