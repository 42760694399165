.yoodict{
    margin-top: 20px;
    margin-bottom: 20px;
}

.trends-card{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.trends-section{
    display: flex;
    flex-direction: column;
}

.trends-word{
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    color: #98029D;
    & sup{  
        top: -2.0em;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #555555;
        display: inline-block;
        @media (max-width: 768px) {
            top: -1.5em;
        }
    }
    @media (max-width: 425px) {
        font-size: 30px;
    }
}

.trend-timeline{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #555555;
    width: 100%;
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 2px solid rgba(196, 196, 196, 0.2);
}

.trend-definition{
    margin: 5px;
}

.trend-definition-title{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: rgba(152, 2, 157, 0.7);
    padding-left: 10px;
    border-left: 3px solid rgba(245, 108, 0, 0.53);
}

.trend-definition-def{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    padding: 13px;
    color: rgba(56, 56, 56, 0.88);
    white-space: pre-wrap;
}

.trend-usage{
    margin: 5px;
}

.trend-usage-title{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: rgba(152, 2, 157, 0.7);
    padding-left: 10px;
    border-left: 3px solid rgba(245, 108, 0, 0.53);
}

.trend-usage-example{
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding: 13px;
    color: rgba(56, 56, 56, 0.88);
}

.more-trends{
    width: 100%;
    border-bottom: 2px solid rgba(196, 196, 196, 0.2);
    padding: 20px 0;
    & a{
        text-decoration: none;
        font-style: normal;
        font-weight: 600; 
        border-bottom: 2px solid;
        color: #F56C00;
    }
}

.also-see{
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
}

.meme-section-header{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #616161;
    margin-bottom: 10px;
    margin-top: 11px;
}

.memes{
    border-radius: 8px;
    height: 150px;
    margin-right: 1em;
}

.similar-trends{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #616161;
    margin: 15px 0 5px 0;
}

.similar-chips{
    background: #FDC2FF;
    cursor: pointer;
    border-radius: 16px;
    padding: 3px 12px;
    width: fit-content;
    margin-top: 5px;
    & a{        
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #490C4B;
        text-decoration: none;
    }
    &:hover{
        & a{
            color: #ffffff;
        }
        background:  #98029D;
    }
}

.trends-footer{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
    & a{
        text-decoration: none;
        color: #333333;
    }
}