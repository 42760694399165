.admin-navbar{
    background: #ffffff;
    width: 25vw;
    padding: 1em;
    display: flex;
    height: inherit;
    flex-direction: column;
    @media (max-width: 986px) {
       width: 30vw;
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.admin-navbar-minimized{
    background: #ffffff;
    width: 40vw;
    padding: 1em;
    height: 100vh;
    display: flex;
    flex-direction: column;
    @media (max-width: 524px) {
       width: 60vw;
    }
}

.top-section{
    margin: 2em 0px;
}

.admin-logo{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 3px 15px;
    background: #98029D;
    border-radius: 50%;
    width: fit-content;
    text-align: center;
    margin: auto;
}

.admin-welcome{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.88);
    text-align: center;
    margin-top: 2em;
    @media (max-width: 986px) {
        font-size: 16px;
    }
}

.section-header{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.88);
    padding-bottom: 0.5em;
    border-bottom: 1px solid #C4C4C4;
    @media (max-width: 986px) {
        font-size: 16px;
    }
}

.upper-section{
    margin: 2em 0;
}

.admin-nav{
    & a{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
        text-transform: uppercase;
        color: #333333;
        &:hover{
            color: #98029D;
        }
        &:focus{
            color: #98029D;
        }
        &:active{
            color: #98029D;
        }
        @media (max-width: 986px) {
            font-size: 16px;
        }
    }
}

.active{
    color: #98029D!important;
    & a{
        color: #98029D!important;
    }
}

.lower-section{ 
    margin: auto;
}

.admin-name{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: rgba(51, 51, 51, 0.88);
    text-align: center;
    @media (max-width: 986px) {
        font-size: 16px;
    }
}

.button-container{
    text-align: center;
    margin-top: 0.5em;
}

.admin-logout{
    background: #FFFFFF;
    border: 2px solid #98029D;
    box-sizing: border-box;
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #616161;
    padding: 3px 12px;
    box-shadow:0 0 0 .2rem #fff;
    transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    @media (max-width: 986px) {
        font-size: 12px;
    }
    &:hover{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:active{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:focus{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}