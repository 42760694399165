.title-text{
    
    font-style: Medium;
    font-size: 20px;
    margin: 15px 0 20px 0;
}

.content-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dropDown{
    margin-top: 10px;
    &:hover{
        cursor: pointer;
    }
}

@keyframes example {
    from {display: none;}
    to {display: block;}
  }

.right-arrow{
    height: 18px;
    margin-top: 18px;
}

.content{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #555555;
}
