.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .pagination > li {
    list-style: none;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    background: #ffffff;
    border: 2px solid #98029D;
    color: #98029D;
    border-radius: 50%;
    padding: 0 5px;
    &:hover{
      background: #98029D;
      color: #fff;
    }
  }

.active-page{
  background: #98029D!important;
  color: #fff!important;
}
