.banner{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.5) 100%), url(../../icons/banner2.jpg);
    background-position-y: center;
}

.header {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
}

.container {
    width: 60vw;
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 768px) {
      width: 70vw;
    }
}
