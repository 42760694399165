.admin-panel{
    display: flex;
    height: 100vh;
}

.navint-headers-type2{
    border-bottom: 2px solid rgba(196, 196, 196, 0.7);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 3em;
    padding-bottom: 0.5em;
}

.addword-panel{
    background: #ffffff;
    margin: 1em;
    border-radius: 5px;
}

.trendsearch-container{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-top: 2em;
    padding-bottom: 2em;
}

#addtrend-search{
    background-color: #ffffff;
    border-radius: 16px;
    width: 30%;
    @media (max-width: 768px) {
        width: 45%;
    }
    @media (max-width: 540px) {
        width: 70%;
    }
}

.update-button{
    font-style: normal;
    font-weight: 600;
    // font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-decoration-line: underline;
    color: #F56C00;
    margin: 0.5em;
    cursor: pointer;
}

.current-words{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: 2em;
}

.currentword-title{
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 28px;
    color: rgba(51, 51, 51, 0.88);
}

.currentword-word{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #98029D;
}

.addnewadmin{
    background: #ffffff;
    margin: 1em;
    border-radius: 5px;
}

.addadmin-row1{
    margin-bottom: 1em;
    padding-top: 2em; 
}

.verifyuser-row1{
    margin-bottom: 1em;
}

.admin-count{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.25px;
    color: #333333;
}

.addadmin-row2{
    padding-bottom: 3em;
}

.verifyuser-row2{
    padding-bottom: 1em;
}

.admins{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 1.25px;
    color: #333333;
}

.delete-admin{
    text-decoration: underline;
    color: #98029D;
    margin-left: 5px;
    &:hover{
        cursor: pointer;
    }
}

.addadmin-row3{
    padding-top: 2em;
    display: flex;
    @media (max-width: 650px) {
        // padding-top: 0;
        flex-direction: column;
    }
}

.verifyuser-row3{
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    @media (max-width: 650px) {
        padding-top: 0;
        flex-direction: column;
    }
}

.add-admin{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1.25px;
    color: #333333;
}

.add-icon{
    height: inherit;
    width: 20px;
    vertical-align: baseline;
    margin: 0 0.5em;
    @media (max-width: 650px) {
        display: none;
    }
}

#addadmin-mail{
    background-color: #ffffff;
    border-radius: 16px;
    width: 30%;
    border-radius: 2px;
    margin-right: 1.5em;
    margin-left: 1em;
    @media (max-width: 650px) {
        margin: 1em 0;
        width: 100%;
    }
}

#search-user{
    background-color: #ffffff;
    border-radius: 16px;
    border-radius: 2px;
    @media (max-width: 650px) {
        margin: 1em 0;
        width: 100%;
    }
}
#verification-input{
    background-color: #ffffff;
    border-radius: 16px;
    width: 30%;
    border-radius: 2px;
    margin-right: 1.5em;
    margin-left: 1em;
    @media (max-width: 650px) {
        margin: 1em 0;
        width: 100%;
    }
}

#verification-input2{
    background-color: #ffffff;
    border-radius: 16px;
    width: 30%;
    border-radius: 2px;
    margin-right: 1.5em;
    margin-left: 1em;
    @media (max-width: 650px) {
        margin: 1em 0;
        width: 100%;
    }
}

.holding-class{
    @media (max-width: 650px) {
        padding-top: 5px;
    }
}