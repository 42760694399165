.navint-logo{
    height: 20px;
    margin-right: 3em;
    @media (max-width: 768px) {  
        margin-right: 0em;
    }
}

.navint-nav{
    width: 100%;
    overflow-y: scroll;
}

.fade.modal.show{
    padding-left: 0px!important;
}

.nav-modal{
    margin-left: 0;
    margin-top: 0;
    left: 0;
    width: fit-content;
}

.navint-header{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    vertical-align: sub;
}

.res-nav-toggle{
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
}

.navint-right{
    text-align: right;
    margin-top: 2em;
    margin-right: 3em;
    @media (max-width: 768px) {      
        display: flex;
        justify-content: space-between;
        margin-left: 3em;
        margin-top: 2em;
    }
    @media (max-width: 375px) {   
        margin-left: 1em;
        margin-right: 1em;
    }
}

.navint-link-name{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: #616161;
    margin-left: 3em;
}

.navint-headers{
    border-bottom: 2px solid rgba(196, 196, 196, 0.7);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0.5em;
}

.headers-holder{
    width: fit-content;
    padding: 5px 15px;
    border-right: 2px solid rgba(196, 196, 196, 0.7);
}

.header-wordname{
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #333333;
}

.header-number{
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #98029D;
}

.sortby{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
}

.sortby-section{
    display: flex;
    margin: 0.5em 3em;
}

.dropbtn {
background-color: transparent;
color: #98029D;
font-size: 18px;
border: none;
cursor: pointer;
margin-left: 0.5em;
}

.dropdown-icon{
margin-left: 0.5em;
vertical-align: baseline;
}

.dropdown {
position: relative;
display: inline-block;
}

.dropdown-content {
color: #98029D;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.words-table{
    margin: 0 2em;
    text-align: center;
    overflow-x: scroll;
}

.table thead th {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: rgba(51, 51, 51, 0.66);
    border-top: 0px;
}

.table thead tr {
    background: #FDE3FE;
}

.table td {
    border-top: 0px;
    border-bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px; 
    max-width: 20%;
    vertical-align: middle;

    // overflow-wrap: anywhere;
    line-height: 24px;
    letter-spacing: 1.25px;
    color: #333333;
}

.table tbody tr {
    background: #ffffff;
    border-top: 1em solid #FAFAFA;
}

.pagenation{
    display: flex; 
    justify-content: center;
}

.split-view{
    display: 'flex';
    justify-content: 'space-evenly';
}