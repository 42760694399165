.yootrends-logo{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 768px) {
        border-radius: 0px;
        width: 100%;
        padding: 0px;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        padding: 25px 35px;
    }
}

.ytlogo{
    padding: 30px 0;
    margin: 0 auto;
    @media (max-width: 768px) {
        border-radius: 0px;
        padding: 0px;
        width: 40%;
        margin: 0;
    }
}

.ytlogo-caption{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #821686;
    margin: 15px 0px 35px 0px;
    text-align: center;
    @media (max-width: 768px) {
        margin: 0px;
        padding-top: 20px;
        margin-right: 5%;
    }
}

.t-o-w{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    @media (max-width: 768px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
    }
}

.tow-header{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #643866;
    text-align: center;
    padding: 20px 0 0 0;
}
.tow-title{
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    color: #643866;
    text-align: center;
}

.tow-word{
    margin-left: 10%;
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 48px;
        text-decoration-line: underline;
        color: #F56C00;
    }
}

.yt-definition-title{
    margin-left: 10%;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
}

.yt-definition-def{
    margin-left: 10%;
    font-style: italic;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
}

.tow-chip{
    cursor: pointer;
    background: linear-gradient(180deg, #532655 0%, rgba(152, 2, 157, 0.61) 100%);
    border-radius: 16px;
    width: fit-content;
    padding: 3px 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    margin: 10px 10px;
    &:hover{
        background: #FDC2FF;
        color: #490C4B;
    }
}

.yt-monthly{
    background: #593A5A;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px;
    @media (max-width: 768px) {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
}

.yt-monthly-header{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FEEFFF;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.39);
}

.yt-month-head{
    font-style: normal;
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    cursor: pointer;
    & :hover{
        color: #FFFFFF;
    }
    & :focus{
        color: #FFFFFF;
    }
    & :active{
        color: #FFFFFF;
    }
    path:hover {
        fill: #FFFFFF;
    }
    path:focus {
        fill: #FFFFFF;
    }
    path:active {
        fill: #FFFFFF;
    }
}

.yt-month-folder{
    margin-right: 10px;
}

.yt-month-ellipse{
    margin-right: 10px;
}

.ellipse-svg{
    margin-right: 10px;
}

.yt-month-words{
    color: #fff;
    margin-left: 25px;
    display: none;
}


.yt-month-word{
    & a{
        color: #fff;
        &:hover{
            color: #fff;
        }
    }
}

.advert-space{
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    @media (max-width: 720px) {
        display: none;
    }
}

.banner-footer{
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    & a{
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        text-align: center;
        line-height: 21px;
        color: #555555;
    }
    @media (max-width: 720px) {
      display: none;
    }
}

.loadmore-buttton{
    border: none;
    margin-top: 6px;
    background: none;
    text-decoration: underline;
    color: #fff;
}