.minimized-alpha-nav{
    display: none;
    @media (max-width: 425px) {
        display: block;
    }
}

.normal-alpha-nav{
    display: block;
    @media (max-width: 425px) {
        display: none;
    }
}

.left-pack{
    width: 50vw;
    margin-right: 2em;
    @media (max-width: 768px) {
        margin: 2em auto !important;
        width: 80vw;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: 720px) {
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    @media (max-width: 425px) {
        width: 95vw;
    }
}

.profile-banner-section{
    width: 30vw;
    @media (max-width: 1110px) {
        width: min-content;
        padding: 0;
    }
    @media (max-width: 720px) {
        width: unset;
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .vertical-map{
        display: none;
    }
  }