.yt-page{
    width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 768px) {
        flex-wrap: wrap-reverse;
        flex-direction: column-reverse;
        width: 100%;
    }
}

.nav-back{
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1.25px;
        color: #FFB667;
    }
}
  
.back-button{
    transform: scaleX(-1);
    height: 16px;
    margin: 0px 10px 6px -5px;
    padding-top: 3px;
}

.yt-left-pack{
    width: 50vw;
    @media (max-width: 768px) {
        width: 80vw;
        margin: 0 auto;
      }
    @media (max-width: 425px) {
      width: 95%;
    }
}

.load-more{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #616161;
    text-align: center;
    margin-bottom: 1em;
    cursor: pointer;
    & img{
        width: 1em;
    }
}

.yt-banner-section{
    padding: 0 30px;
    width: 30vw;
    margin-top: 45.5px;
    @media (max-width: 768px) {
        padding: 0;
        width: 100%;
        margin-top: 0px;
    }
}