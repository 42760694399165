.spec-button{
    background: #FFFFFF;
    border: 2px solid #98029D;
    box-sizing: border-box;
    border-radius: 20px;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    margin-left: 5px;
    color: #616161;
    padding: 3px 3em;
    box-shadow:0 0 0 .2rem #fff;
    transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    &:hover{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:active{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:focus{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.viewmore-button{
    background: #FFFFFF;
    border: 2px solid #98029D;
    box-sizing: border-box;
    border-radius: 20px;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    margin-left: 5px;margin-top: 5px;
    line-height: 19px;
    color: #616161;
    min-width: max-content;
    box-shadow:0 0 0 .2rem #fff;
    transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    &:hover{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:active{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:focus{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.page-button{
    background: #FFFFFF;
    border: 2px solid #98029D;
    box-sizing: border-box;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #616161;
    min-width: max-content;
    box-shadow:0 0 0 .2rem #fff;
    transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    &:hover{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:active{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
    &:focus{
        background: #98029D!important;
        color: #fff!important;
        border: 2px solid #98029D!important;
        box-shadow:0 0 0 .2rem #fff!important;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}
