.wordupdate-modal{
    min-width: 60vw;
    margin-left: auto;
    margin-right: auto;
}

.wordupdate-modal>.modal-content {
    background: none;
}

.trendupdate-modal{
    min-width: 60vw;
    margin-left: auto;
    margin-right: auto;
}

.trendupdate-modal>.modal-content {
    background: none;
}

.wordupdate-form{
    display: flex;
    justify-content: center;
}

.form-control{
    & :focus {
        color: #495057;
        background-color: #fff;
        border: 1px solid #98029D;
        outline: 0;
        box-shadow: none;
    }
}

#wordupdateForm{
    background-color: #ffffff;
    padding: 40px 30px;
    border-radius: 25px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 550px) {
        width: 90vw;
        margin: 40px auto;
    }
}

.wordupdateform-header{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 72px;
    color: #555555;
    text-align: center;
    margin-bottom: 10px;
}

.form-label{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
}

.req{
    color: red;
    font-weight: bold;
}

.form-group {
    margin-bottom: 2rem;
    @media (max-width: 768px) {
        display: block;
        max-width: 100%!important; 
    }
}


.wordupdate{
    display: flex;
    @media (max-width: 768px) {
        display: block;
    }
}

#wordupdateButton{
    background: #770082;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding: 20px;
}

.text-muted{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right!important;
    color: #333333;
    margin-top: 20px;
    & a {
        
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        color: #F56C00;
    }
}



#submitWordButton{
    background: #98029D;
    color: #FDBDFF;
    border-radius: 4px;
    border-color: #fff;
    &:hover{
        color: #98029D;
        background: #FDBDFF;
    }
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.submit-button{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin: 10px 0px;
    &:hover{
        color: #98029D;
    }
}

#cancelWordButton{
    background: #ffffff;
    border-radius: 4px;
    border-color: #fff;
    color: #A685A7;
    &:hover{
        color: #F400FD;
    }
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        margin-top: 5px;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.cancel-button{
    
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin: 10px 0px;
}

//MODAL STYLING
.modal-header{
    border-bottom: none;
    & .close {
    color: #fff;
    font-size: xx-large;
    }
}


.close-modal-button{
    text-align: right;
    width: 100%;
    & img{
        height: 30px;
        cursor: pointer;
    }
}

.modal-body{
    & h4{
        
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 37px;
        text-align: center;
        color: #FFFFFF;
    }
    & p{
        text-align: center;
        margin: 10%;
    }
    & a{
        
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #FFBC88;
    }
}

.modal-content{
    background-color: #865488;
}

.modal-backdrop.show {
    opacity: 0.1;
}

//MODAL STYLING
.modal-header{
    border-bottom: none;
    & .close {
    color: #fff;
    font-size: xx-large;
    }
}
.modal-body{
    & h4{
        
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 37px;
        text-align: center;
        color: #FFFFFF;
    }
    & p{
        text-align: center;
        margin: 10%;
    }
    & a{
        
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        color: #FFBC88;
    }
}

.modal-content{
    background-color: #865488;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.confirmation-buttons{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

#sumbitYes{
    border: 1px solid #FB91FF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    background-color: #865488;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FDBDFF;
    margin-right: 10px;
    padding: 10px 25px;
    &:hover{
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #98029D;
    }    
    &.btn-primary{
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

#sumbitNo{
    border: 1px solid #FB91FF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    background-color: #865488;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FDBDFF;
    margin-right: 10px;
    padding: 10px 25px;
    &:hover{
        background: #FFFFFF;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #98029D;
    }    
    &.btn-primary{
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.dual-buttons>button {
    margin-right: 1em;
}