.editprofile-form{
    display: flex;
    justify-content: center;
}
input:disabled, button:disabled {
    cursor : not-allowed
}
#editprofileForm{
    width: 50vw;
    background-color: #ffffff;
    padding: 40px 30px;
    margin: 40px;
    border-radius: 25px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
        width: 60vw;
    }
    @media (max-width: 425px) {
        width: 85vw;
    }
}

.editp{
}

.nav-back{
  margin-bottom: 20px;
  & a{
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #F56C00;
      margin-bottom: 10px;
  }
}

.back-button{
  transform: scaleX(-1);
  height: 16px;
  margin: 0px 10px 6px -5px;
  padding-top: 3px;
}

.form-header{
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    margin-bottom: 20px;
    color: #555555;
}

.form-group{
    padding-left: 0px;
}

.form-label{
    
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

.form-group {
    margin-bottom: 2rem;
}

#editprofileButton{
    background: #98029D;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 10px 20px;
    &:hover{
        background: #FDBDFF;
        color: #98029D;
    }  
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

#deleteAccountButton{
    background: #ef1c1c;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 10px 20px;
    &:hover{
        background: #FDBDFF;
        color: #98029D;
    }  
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
        transition:border-color 0s ease-in-out,box-shadow 0s ease-in-out;
    }
}

.text-muted{
    
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right!important;
    color: #333333;
    margin-top: 20px;
    & a {
        
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        color: #F56C00;
    }
}

.editprofile-alert{
    justify-content: center;
    height: 80vh;
}

.deleteaccountheader{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    margin-top: 15px;
    margin-bottom: 15px;
}

//MODALS STYLING
#modalButtonStyle{
    background: #98029D;
    backdrop-filter: blur(250px);
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    margin-left: 3%;
    margin-right: 3%;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 10px 20px;
    padding: 10px 25px;
    &:hover{
        background: #FDBDFF;
        color: #98029D;
    }  
    &:active{
        border-color: #ffffff;
    }
    &:focus{
        border-color: #ffffff;
    }
    &:visited{
        border-color: #ffffff;
    }
    &.btn-primary{
        border:none;
        box-shadow:0 0 0 .2rem #fff;
    }
}

.modelConfirmation-buttons{
    text-align: center;
    margin-top: 5%;
    margin-bottom: 50px;
}

.editProfileModals{
    background: #fff;
}

.editProfileModalsBody{
    text-align: center;
    color: #000;
    background: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
}

.checkicon{
    height: 50px;
    margin-bottom: 5%;
}