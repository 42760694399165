.card{
    border: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.wordcardhead{
    width: 100%;
    border-radius: 20px 20px 0 0;
    @media (max-width: 720px) {
        width: 100%;
      }
}

.word-card-head{
    margin: 20px 5px 0;
}

.row-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media (max-width: 375px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.word{
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #98029D;
    & sup{
        top: -2.0em;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #555555;
        display: inline-block;
    }
    @media (max-width: 768px) {
        fonts-size: 35px;
        & sup{
            font-size: 12px;
        }
    }
}

.add-definition{
    background: #FFE5D1;
    border-radius: 36px;
    float: right;
    min-width: fit-content;
    & a{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #F56C00;
        text-decoration: none;
        padding: 15px;
        vertical-align: text-top;
        @media (max-width: 920px) {
            font-size: 12px;
        }
    }
}

.word-literal-translation{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.88);
    mix-blend-mode: normal;
    }

.word-lit-translation{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #555555;
    display: block;
    margin-top: 10px;
}
