.pagewide-footer{
    background: #8C8C8C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px rgba(0, 0, 0, 0.24);
    
    padding: 2%;
    color: #ffffff;
    // position: absolute;
    width: 100%;
    text-align: center;
    & a{
        color: #ffffff;
        // margin-right: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
        &:hover{
            text-decoration: none;
            color: #ffffff;
        }
    }
}