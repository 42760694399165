.ydt-fluid{
    width: 70vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 768px) {
        // flex-wrap: wrap-reverse;
        // width: 90vw;
    }
    @media (max-width: 768px) {
        flex-wrap: wrap-reverse;
        width: 100%;
    }
}

.slug-wordcard{
    margin-bottom: 1em;
}

.normal-alpha-nav{
    display: block!important;
}

.slug-left-pack{
    width: 100vw;
    margin-right: 3em;
    @media (max-width: 768px) {
        width: 80vw!important;
        margin: auto!important;
      }
    @media (max-width: 425px) {
      width: 95%!important;
    }
}

.hp-slug-banner-section{ 
    margin-left: 10px;
    margin-top: 20px;
    width: 60vw;
    @media (max-width: 768px) {
        width: 100vw;
        padding: 0;
        margin-top: 0;
        margin-bottom: 20px;
        margin-left: 0px;
    }
}


@media screen and (max-width: 768px) {
    .vertical-map{
        display: none;
    }
  }