body{
  background-color: #FAFAFA;
}

.privacy{
    width: 60vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      width: 70vw;
    }
}

.nav-back-pp{
  margin-bottom: 20px;
  & a{
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #F56C00;
      margin-bottom: 10px;
  }
}

.privacy-intro{
  // font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #555555;
}

.back-button-pp{
  transform: scaleX(-1);
  height: 16px;
  margin: 0px 10px 6px -5px;
  padding-top: 3px;
}

