.profile-section{
    border-radius: 36px 36px 0 0; 
}

.psection-head{
    display: flex;
    justify-content: space-between;
    @media (max-width: 1125px) {
        flex-direction: column-reverse;
    }
}

.psection-row-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    @media (max-width: 1125px) {
        justify-content: flex-start;
        margin-top: 10px;
    }
    @media (max-width: 425px) {
        align-items: flex-start;
        flex-direction: column;
    }
}

.addbottomborder{
    border-bottom: 1px solid rgba(196, 196, 196, 0.3);;
}

.psection-icon{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    padding: 0 25px;
    background: #FDD2FF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    & h4{
        font-style: normal;
        font-weight: 600;
        font-size: 100px;
        text-transform: uppercase;
        color: #F56C00;
    }
}
.name-sect{
    margin-left: 10px;
    margin-top: 10px;
}
.psection-username{
    text-transform: capitalize;font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #555555;
}

.profile-verification-icon{
    height: 32px;
    margin-left: 5px;
}

.psection-userhandle{
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #555555;
}

.psection-joindate{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 10px;
}

.psection-words-header{
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 48px;
    color: rgba(51, 51, 51, 0.87);
}

.nav-back-pf{
    margin-top: 0.5em;
    & a{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 1.25px;
        color: #F56C00;
    }
  }
  
  .back-button-pf{
    transform: scaleX(-1);
    height: 16px;
    margin: 0px 10px 6px -5px;
    padding-top: 3px;
  }

.psection-wd{
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 48px;
    color: rgba(51, 51, 51, 0.87);
    margin-left: 18% ;
}

.wordsdefbyuser{
    height: 40vh;
    overflow: scroll;
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c4c4c4; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}

.wordsdef-row-2{
    margin-left: 18%;
}

.wordsdef{
    width: 85%;
}

.wordsdef-number{
    margin-right: 15px;
    background: #FFD2AF;
    color: #666666;
    font-size: 16px;
    border-radius: 50%;
    padding: 2px 8px;
}

.wordsdef-title-text{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 13px;
    color: #333333;
}

.wordsdef-up-arrow{
    height: 13px;
}

.wordsdef-down-arrow{
    height: 13px;
    transform: scaleY(-1);
}

.wordsdef-content-header{
    padding: 15px 0;
    border-bottom: 1px solid rgba(196, 196, 196, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wordsdef-dropDown{
    margin-top: 10px;
    &:hover{
        cursor: pointer;
    }
}

.wordsdef-content{
    margin-top: 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #F56C00;
    background: #FFF1E6;
    border-radius: 8px;
    padding: 5px 10px;
}

.worddefbottom{
    border-radius: 0 0 36px 36px;
}